import "./index.css";
import logo from "../../../assets/icons/algoCipherAutomateIcon.svg";
import BgImg from "../../../assets/images/backgroundImg.svg";
import { Stack } from "@mui/material";

interface ILoadingScreenProps {
  message?: string;
}

const LoadingScreen = ({ message }: ILoadingScreenProps) => {
  return (
    <Stack
      className="center-loading"
      style={{ backgroundImage: `url(${BgImg})` }}
    >
      <img className="loading-image" src={logo} />
      {message && <h1 className="loading-text">{message}</h1>}
    </Stack>
  );
};

export default LoadingScreen;

export const DashboardLoadingScreen = ({ message }: ILoadingScreenProps) => {
  return (
    <Stack className="dashboard-center-loading">
      <img className="loading-image" src={logo} />
      dasda
      {message && <h1 className="loading-text">{message}</h1>}
    </Stack>
  );
};
