import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getSingleProject } from "../../../services/api/projectApi";
import { IGetProject } from "../../../types/project";
import useNotification from "../../../hooks/useNotification";
import BudgetApprovalTab from "../budget-approval/budget-approval-tab";
import PendingPaymentTab from "../pending-payment/pending-payment-tab";
import DevelopmentTab from "../development/development-tab";
import { AppContext } from "../../../context";
import Stepper from "../../../components/Stepper";
import DoneTab from "../done/done-tab";
import { ArrowRight } from "phosphor-react";

// asset
import confirmOrder from "../../../assets/images/successful.svg"

const ProjectId = () => {
  const { api } = useContext(AppContext);
  const [index, setIndex] = useState(5);
  const [selectedProject, setSelectedProject] = useState<IGetProject | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const { notify } = useNotification();
  const { projectId } = useParams();

  const getLocation = () => {
    if (!selectedProject) return;

    const { status } = selectedProject;

    if (status === "inreview") {
      setIndex(2);
    } else if (status === "pending") {
      setIndex(3);
    } else if (status === "develop") {
      setIndex(4);
    } else {
      setIndex(5);
    }
  };

  const getProjectDetails = async () => {
    if (projectId && api)
      try {
        const res = await getSingleProject({ api, projectId });
        if (res) {
          setSelectedProject(res);
        }
      } catch (error: any) {
        notify(
          "Error fetching project details, Please try that again or contact support if issue persists",
          "error"
        );
      }
  };

  useEffect(() => {
    if (api) {
      getProjectDetails();
    }
  }, [api]);

  useEffect(() => {
    getLocation();
  }, [selectedProject]);

  return (
    <section style={{ height: "100%" }}>
      {selectedProject && (
        <>
          {index < 5 && <Stepper index={index} />}

          {index === 2 && (
            <BudgetApprovalTab
              selectedProject={selectedProject}
              setIndex={setIndex}
            />
          )}

          {index === 3 && (
            <PendingPaymentTab
              selectedProject={selectedProject}
              setIndex={setIndex}
              refreshProject={getProjectDetails}
            />
          )}

          {index === 4 && (
            <DevelopmentTab 
              img={confirmOrder}
              header="Payment summary"
              text="Your transaction was successful, you will get a receipt in your email"
              cost={1000}
              referenceId="12hbvsiyavsdcv"
              buttonText="Continue to project"
              icon={<ArrowRight size={25} weight="thin" color='#FFF' /> }
              iconType="right"
              paymentMethod="Debit Card, VISA"
              date="29 - 10 - 2023"
            />
          )}

          {index === 5 && (
            <DoneTab
              selectedProject={selectedProject}
            />
          )}
        </>
      )}
    </section>
  );
};

export default ProjectId;
