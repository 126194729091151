import { Typography, Avatar, CircularProgress, Tooltip } from "@mui/material";
import {
  NotificationPageContainer,
  NotificationSection,
  Empty,
  EmptyContent,
  Text,
} from "./style";

// assets
import InfiniteScroll from "react-infinite-scroll-component";
import {
  deleteUserNotification,
  getUserNotification,
  getUserNotifications,
} from "../../services/api/notificationApi";
import useApi from "../../hooks/api/useApi";
import { useContext, useEffect, useRef, useState } from "react";
import { INotification } from "../../types/notification";
import { formatDate } from "../../services/helpers/helpers";
import { useNavigate } from "react-router-dom";
import { notificationTypes } from "./notificationTypes";
import { IQueryParams } from "../../types/apiTypes";
import { Page } from "../../types/apiResponse";
import { AppContext, HubContext } from "../../context";
import { HubEvents, OnNotificationHubData } from "../../types/hub";
import { AppState } from "../../context/types";

// assets
import emptyCart from "../../assets/icons/empty-cart.svg";
import UserImage from "../../components/UserImage";
import Algocipheralc from "../../assets/icons/algocipher-alc.svg";

const Notifications = () => {
  const navigate = useNavigate();
  const { on, off } = useContext(HubContext);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const notificationsRef = useRef(notifications);
  const { notificationCount, setNotificationCount, api } =
    useContext(AppContext);
  const apiRef = useRef(api);

  const [queryParams, setQueryParams] = useState<IQueryParams>({
    pageNumber: 1,
    sortOrder: "asc",
    pageSize: 20,
  });

  useEffect(() => {
    on(HubEvents.onNotification, hubOnNotificationCallback);
    return () => {
      off(HubEvents.onNotification, hubOnNotificationCallback);
    };
  }, []);

  useEffect(() => {
    if (api) {
      getUserNotifications(api, queryParams).then(
        (data: Page<INotification>) => {
          if (data) {
            const newNotifications = data.result.filter(
              (item) => !notifications.includes(item)
            );
            setNotifications([...notifications, ...newNotifications]);
          }
        }
      );
    }
  }, [api, queryParams]);

  useEffect(() => {
    apiRef.current = api;
  }, [api]);

  useEffect(() => {
    notificationsRef.current = notifications;
  }, [notifications]);

  const hubOnNotificationCallback = (
    payload: OnNotificationHubData,
    appState: AppState
  ) => {
    const existingNotification = notifications.find(
      (item) => item.id === payload.Id
    );
    if (existingNotification) return;
    apiRef.current &&
      getUserNotification(apiRef.current, payload.Id).then((notification) => {
        setNotifications([notification, ...notificationsRef.current]);
      });
  };

  const handleScrollNext = async () => {
    console.log("scroll next called");
    const query = { ...queryParams };
    query.pageNumber++;
    setQueryParams(query);
  };

  const getNotificationType = (type: string) => {
    return notificationTypes.find((x) => x.type == type);
  };

  const handleNotificationClicked = (notification: INotification) => {
    api && deleteUserNotification(api, notification.id);
    const typeInfo = getNotificationType(notification.type);
    navigate(`/${typeInfo?.route}/${notification.contentId}`);
    setNotificationCount(Math.max(notificationCount - 1, 0));
  };

  return (
    <>
      <NotificationPageContainer>
        <NotificationSection>
          {notifications.length === 0 ? (
            <Empty>
              <EmptyContent>
                <img src={emptyCart} alt="empty-cart" />
                <Text style={{ fontWeight: 500 }}>No notifications yet</Text>
              </EmptyContent>
            </Empty>
          ) : (
            <InfiniteScroll
              dataLength={notifications.length}
              hasMore={true}
              next={handleScrollNext}
              loader={
                <div className="loader">
                  <CircularProgress
                    style={{}}
                    color="inherit"
                    size={20}
                    disableShrink
                  />
                </div>
              }
            >
              {notifications.map((item) => {
                const type = getNotificationType(item.type);
                let utc = new Date(item.timeStamp);
                let localTime = new Date(utc.toLocaleDateString());
                const formattedDate = formatDate(localTime);
                return (
                  <div
                    className="notification-feeds-box"
                    onClick={() => handleNotificationClicked(item)}
                  >
                    <div>
                      <img src={type?.img} />
                    </div>
                    <div className="notification-feed-second-box">
                      <div className="notification-name-and-date-section">
                        <Tooltip title={item.title}>
                          <Typography variant="h3">{item.title}</Typography>
                        </Tooltip>
                        <Typography variant="h6">{formattedDate}</Typography>
                      </div>
                      <div className="notification-description-box">
                        <Typography variant="h6">{item.text}</Typography>
                        <div className="from-and-goto-box">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              width: "100%",
                            }}
                          >
                            {item.byUser ? (
                              <div style={{ width: "30px", height: "30px" }}>
                                <UserImage />
                              </div>
                            ) : (
                              <img
                                src={Algocipheralc}
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "100px",
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          )}
        </NotificationSection>
      </NotificationPageContainer>
    </>
  );
};

export default Notifications;
