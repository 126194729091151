import Folder from "../../assets/icons/folder.svg";
import { Avatar, Tooltip, Typography } from "@mui/material";
import {
  BoldTableTextStyle,
  FilePickerBoxContent,
  FirstBarFlexedContents,
  RegularTableTextStyle,
} from "./style";
import FilePicker, { IFileType } from "../../components/Shared/FilePicker";
import { CSSProperties, useContext, useEffect, useMemo, useState } from "react";
import Img from "../../assets/icons/img.svg";
import Video from "../../assets/icons/video.svg";
import Docs from "../../assets/icons/docs.svg";
import ProjectPicker from "../../components/Shared/ProjectPicker";
import { IProjectId } from "../../types/project";
import FileLoadingBar from "../../components/Shared/FileLoadingBar";
import MediaPreview from "../../components/Shared/MediaPreview";
import useApi from "../../hooks/api/useApi";
import {
  getUploadTokens,
  uploadChunksToStorage,
} from "../../hooks/api/useStorage";
import useNotification from "../../hooks/useNotification";
import { getUserFiles, saveFileMetaData } from "../../services/api/fileApi";
import { IFile, IUploadFileMetadataPayload } from "../../types/file";
import CustomTable, {
  ICustomTableColumn,
} from "../../components/Shared/CustomTable";
import { IQueryParams } from "../../types/apiTypes";
import { bytesToMegaBytes, getFileType } from "../../services/helpers/helpers";
import CircledFile from "../../assets/icons/circled-file.svg";
import { AppContext } from "../../context";
import Logo from "../../assets/images/alc.svg";
import DownloadButton from "../../assets/icons/download-btn.svg";
import InactiveFile from "../../assets/icons/inactive-files.svg";
import UploadToCloud from "../../assets/icons/upload-to-cloud.svg";

const allowedFileTypes: IFileType[] = [
  {
    name: "Photos",
    icon: Img,
    mediaTypes: "image/*",
  },
  {
    name: "Video",
    icon: Video,
    mediaTypes: "video/*",
  },
  {
    name: "Documents",
    icon: Docs,
    mediaTypes:
      "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
];

const tableColumns: ICustomTableColumn[] = [
  {
    id: "name",
    text: "File name",
    canSort: true,
  },
  {
    id: "size",
    text: "File size",
    canSort: true,
  },
  {
    id: "sentBy",
    text: "Sent by",
    canSort: true,
  },
  {
    id: "projectName",
    text: "Project name",
    canSort: true,
  },
  {
    id: "uploaded",
    text: "Date uploaded",
    canSort: true,
  },
  {
    id: "action",
    text: "",
  },
];

const filterOptions = [
  {
    id: "all",
    name: "View all",
  },
  {
    id: "mine",
    name: "Your files",
  },
  {
    id: "platform",
    name: "Shared files",
  },
];
interface IFileData extends IFile {
  date: Date;
}

const filePickerButtonStyle: CSSProperties = {
  position: "relative",
  borderRadius: "50%",
  border: "none",
  background: "#f7f8f7",
  width: "70px",
  height: "70px",
};

const Files = () => {
  const { notify } = useNotification();
  const { user, lookup, api } = useContext(AppContext);
  const [filePickerDisable, setFilePickerDisable] = useState(false);
  const [showProjectPicker, setShowProjectPicker] = useState(false);
  const [project, setProject] = useState<IProjectId>();
  const [file, setFile] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showUploadLoader, setShowUploadLoader] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [queryParam, setQueryParam] = useState<IQueryParams>({
    pageNumber: 1,
    pageSize: 10,
    search: "",
    filterBy: "",
    sortBy: "",
    sortOrder: "asc",
  });
  const [files, setFiles] = useState<IFileData[]>([]);

  useEffect(() => {
    if (project) {
      uploadFile();
    }
  }, [project]);

  useEffect(() => {
    if (api && !isLoading) {
      getFiles();
    }
  }, [api, queryParam]);

  const getFiles = () => {
    if (api) {
      setIsLoading(true);
      getUserFiles(api, queryParam)
        .then((data) => {
          setTotalRecords(data.total);
          const fileData = data.result.map((item) => {
            return { ...item, date: new Date(item.uploadDate) };
          });
          setFiles(fileData);
        })
        .catch((err) => {
          notify(
            "Error fetching your files, Please try again or contact support if issue persists",
            "error"
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const handleFileSelected = (file: File) => {
    setFile(file);
    setShowPreview(true);
  };

  const handleFileUploadProgress = (uploadedBytes: number) => {
    setUploadProgress(uploadedBytes);
  };

  const uploadFile = async () => {
    if (!api) return;
    const uploadTokens = await getUploadTokens(api);
    if (!uploadTokens) {
      notify("Something went wrong, please try that again ", "error");
      setFilePickerDisable(false);
      setShowUploadLoader(false);
      return;
    }
    try {
      const url =
        uploadTokens.accountUrl + uploadTokens.projectFileContainerToken;
      var uploadRes = await uploadChunksToStorage(
        url,
        uploadTokens.projectFileContainerName,
        file.name,
        file,
        handleFileUploadProgress
      );
      if (!uploadRes || uploadRes.bytes < file.size) {
        throw Error();
      }
      const payload: IUploadFileMetadataPayload = {
        projectId: project?.projectId ?? "",
        name: file.name,
        sizeBytes: file.size,
        uri: uploadRes.uri,
        type: getFileType(file.type, lookup),
      };
      const result = await saveFileMetaData(api, payload);
      if (!result) {
        throw Error();
      }
      notify("File uploaded successfully", "success");
    } catch {
      notify("Something went wrong, please try that again ", "error");
    } finally {
      setFilePickerDisable(false);
      setShowUploadLoader(false);
    }
  };

  const handleProjectSelected = (selectedProject: IProjectId) => {
    setFilePickerDisable(true);
    setShowProjectPicker(false);
    setShowUploadLoader(true);
    setProject(selectedProject);
  };

  const handlePreviewSubmit = () => {
    setShowPreview(false);
    setShowProjectPicker(true);
  };

  const handlePageChanged = (page: number, size: number) => {
    const newQuery = { ...queryParam, pageNumber: page, pageSize: size };
    setQueryParam(newQuery);
  };

  const handleSortChanged = (sortBy: string, sortOrder: string) => {
    const newQuery = {
      ...queryParam,
      pageNumber: 1,
      sortBy: sortBy,
      sortOrder: sortOrder,
    };
    setQueryParam(newQuery);
  };

  const handleFilterChanged = (filterBy: string) => {
    const filterOption = filterOptions.find((item) => item.name == filterBy);
    if (filterOption) {
      const newQuery = {
        ...queryParam,
        pageNumber: 1,
        filterBy: filterOption.id,
      };
      setQueryParam(newQuery);
    }
  };

  const handleSearch = (search: string) => {
    const newQuery = {
      ...queryParam,
      search: search,
      pageNumber: 1,
    };
    setQueryParam(newQuery);
  };

  const getIcon = (key: string) => {
    console.log("getIcon", key);
    if (key === "photo") return Img;
    if (key === "video") return Video;
    if (key === "document") return Docs;
    return CircledFile;
  };

  const fileType = useMemo(() => {
    if (!file) return "document";
    else if (file.type.includes("image")) return "image";
    else if (file.type.includes("video")) return "video";
    else return "document";
  }, [file]);

  const tableData = useMemo(() => {
    return files.map((item) => {
      const fileSize = `${
        Math.round(bytesToMegaBytes(item.sizeBytes) * 100) / 100
      }MB`;
      const sentBy =
        item.sentBy == "user" ? user?.firstName ?? "User" : "Algocipher";
      const sentByImg = item.sentBy == "user" ? user?.imageUri : Logo;
      const twoDigits = (val: number) => {
        if (val.toString().length < 2) return `0${val}`;
        return val;
      };
      const uploadDate = `${twoDigits(item.date.getDate())} / ${twoDigits(
        item.date.getMonth() + 1
      )} / ${item.date.getFullYear()}`;

      const uri = item.uri?.slice(0, item.uri.indexOf("?"));
      return {
        name: (
          <Tooltip title={item.name}>
            <Typography sx={BoldTableTextStyle}>
              <Avatar src={getIcon(item.fileType ?? "")} /> {item.name}
            </Typography>
          </Tooltip>
        ),
        size: (
          <Tooltip title={fileSize}>
            <Typography sx={RegularTableTextStyle}>{fileSize}</Typography>
          </Tooltip>
        ),
        sentBy: (
          <Tooltip title={sentBy}>
            <Typography sx={BoldTableTextStyle}>
              <Avatar src={sentByImg} /> {sentBy}
            </Typography>
          </Tooltip>
        ),
        projectName: (
          <Tooltip title={item.projectName}>
            <Typography sx={RegularTableTextStyle}>
              {item.projectName}
            </Typography>
          </Tooltip>
        ),
        uploaded: (
          <Tooltip title={uploadDate}>
            <Typography sx={RegularTableTextStyle}>{uploadDate}</Typography>
          </Tooltip>
        ),
        action: (
          <a href={uri} target="_blank">
            <img src={DownloadButton} style={{ marginRight: "20px" }} />
          </a>
        ),
      };
    });
  }, [files]);

  return (
    <>
      {showPreview ? (
        <MediaPreview
          onSubmit={handlePreviewSubmit}
          file={file}
          close={() => setShowPreview(false)}
          showTextBox={false}
        />
      ) : (
        <>
          <FilePickerBoxContent>
            <FilePicker
              disabled={filePickerDisable}
              onSelected={handleFileSelected}
              allowedTypes={allowedFileTypes}
              showLoader={showUploadLoader}
              buttonStyle={filePickerButtonStyle}
              buttonImg={UploadToCloud}
              flexDirection="column"
              top="110%"
              loader={
                <FileLoadingBar
                  fileName={file?.name}
                  fileSize={file?.size}
                  progress={uploadProgress}
                  onComplete={() => {}}
                />
              }
            />
            <Typography variant="h5">
              Click to upload a document.
              <br /> Document must be 800 x 400px - Max 20Mb
            </Typography>
          </FilePickerBoxContent>
          <div
            style={{
              bottom: "90%",
              width: "100%",
              height: "0px",
              margin: "auto",
              position: "sticky",
              zIndex: "2",
            }}
          >
            <ProjectPicker
              title={`What project would this ${fileType} be associated with?`}
              onSubmit={handleProjectSelected}
              open={showProjectPicker}
            />
          </div>
          <CustomTable
            filters={filterOptions.map((x) => x.name)}
            columns={tableColumns}
            data={tableData}
            pageSize={queryParam.pageSize}
            pageNumber={queryParam.pageNumber}
            totalRecords={totalRecords}
            onPageChange={handlePageChanged}
            onSort={handleSortChanged}
            onSearch={handleSearch}
            onFilter={handleFilterChanged}
            isLoading={isLoading}
          />
        </>
      )}
    </>
  );
};

export default Files;
