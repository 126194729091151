import React, {
  CSSProperties,
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import UserImage from "../../components/UserImage";
import { AppContext, HubContext } from "../../context";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Checkbox,
  CircularProgress,
  Divider,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import {
  Header,
  Deets,
  Wrapper,
  InputContainer,
  CancelBtn,
  DeleteBtn,
} from "./chat-room-style";
import useApi from "../../hooks/api/useApi";
import useNotification from "../../hooks/useNotification";
import {
  deleteMessage,
  getMessage,
  saveMessage,
  sendMessage,
  unsaveMessage,
} from "../../services/api/chatApi";
import { IGetMessage, ISendMessage } from "../../types/chat";
import { getTimeFromISO } from "../../services/helpers/useChangeDateFormat";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import FilePicker, { IFileType } from "../../components/Shared/FilePicker";
import MediaPreview from "../../components/Shared/MediaPreview";
import FileLoadingBar from "../../components/Shared/FileLoadingBar";
import { changeDateFormatToTimelineFormat } from "../../services/helpers/useChangeDateFormat";

import {
  getUploadTokens,
  uploadChunksToStorage,
} from "../../hooks/api/useStorage";
import { IGetProject } from "../../types/project";
import { getSingleProject } from "../../services/api/projectApi";
import { getFileType, getStatusColors } from "../../services/helpers/helpers";
import { HubEvents } from "../../types/hub";

// assets
import phoneIcon from "../../assets/icons/chat-phone.svg";
import arrowIcon from "../../assets/icons/arrow-down.svg";
import folder from "../../assets/icons/folder-open.svg";
import closeButton from "../../assets/icons/chat-cancel-icon.svg";
import inactiveSave from "../../assets/icons/inactive-save-icon.svg";
import inactiveDelete from "../../assets/icons/inactive-delete-icon.svg";
import activeSave from "../../assets/icons/active-save-icon.svg";
import activeDelete from "../../assets/icons/active-delete-icon.svg";
import savedMessageIcon from "../../assets/icons/saved-message-icon.svg";
import Img from "../../assets/icons/img.svg";
import Video from "../../assets/icons/video.svg";
import Docs from "../../assets/icons/docs.svg";
import GreenButton from "../../assets/icons/green-button-with-paperPlane.svg";
import budgetIcon from "../../assets/icons/budget-icon.svg";
import projectIcon from "../../assets/icons/chat-project-icon.svg";
import pendingIcon from "../../assets/icons/pending-Icon.svg";
import updateIcon from "../../assets/icons/update-Icon.svg";
import {
  Construction,
  Pending,
  RestaurantMenuRounded,
  TaskAltRounded,
  Visibility,
} from "@mui/icons-material";
import { updateImportEqualsDeclaration } from "typescript";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const allowedFileTypes: IFileType[] = [
  {
    name: "Photos",
    icon: Img,
    mediaTypes: "image/*",
  },
  {
    name: "Video",
    icon: Video,
    mediaTypes: "video/*",
  },
  {
    name: "Documents",
    icon: Docs,
    mediaTypes:
      "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
];

const filePickerStyles: CSSProperties = {
  marginLeft: "-20px",
};

const emptyMessage: ISendMessage = {
  nonce: "",
  text: undefined,
  fileType: undefined,
  fileUri: undefined,
  replyTo: undefined,
};

const ChatRoom = () => {
  const { chatId } = useParams();
  const { on, off } = useContext(HubContext);
  const { notify } = useNotification();
  const { user, lookup, api } = useContext(AppContext);
  const apiRef = useRef(api);
  const [queryParams, setQueryParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    sortOrder: "asc",
  });

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mediaPreview, setMediaPreview] = useState(false);
  const [isLoadingBar, setIsLoadingBar] = useState(false);
  const [testProgress, setTestProgress] = useState(0);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [messageList, setMessageList] = useState<IGetMessage[]>([]);
  const [projectDetails, setProjectDetails] = useState<IGetProject>();
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [messageIds, setMessageIds] = useState<string[]>([]);
  const [savedMessage, setSavedMessage] = useState(false);
  const [messageLoader, setMessageLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [chatPayload, setChatPayload] = useState<ISendMessage>(emptyMessage);
  const [file, setFile] = useState<any>();
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingIcon, setIsLoadingIcon] = useState(true);

  const messageListRef = useRef(messageList);
  const textInputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    on(HubEvents.onChatMessage, hubOnMessageCallback);
    return () => {
      off(HubEvents.onChatMessage, hubOnMessageCallback);
    };
  }, []);

  useEffect(() => {
    apiRef.current = api;
  }, [api]);

  useEffect(() => {
    messageListRef.current = messageList;
  }, [messageList]);

  const handleOnTextChange = (text: string) => {
    const payload = {
      ...chatPayload,
      text: text,
    };
    setChatPayload(payload);
  };

  const handleOnFileSelected = (file: File) => {
    if (file) {
      setFile(file);
      setChatPayload({
        ...chatPayload,
        fileUri: URL.createObjectURL(file),
        fileType: getFileType(file.type, lookup),
      });
      setMediaPreview(true);
    }
  };

  const hubOnMessageCallback = (payload: IGetMessage) => {
    const existingMessage = messageList.find(
      (item) => item.messageId === payload.messageId
    );
    if (existingMessage) return;
    chatId &&
      apiRef.current &&
      getMessage(apiRef.current, chatId, queryParams).then((message) => {
        setMessageList([...message.result, ...messageListRef.current]);
      });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setShowModal(true);
  };

  const storeMessage = async () => {
    if (chatId && api)
      try {
        await saveMessage(api, chatId, messageIds);
      } catch (error: any) {
        notify(error.message, "error");
      }
  };

  const unStoreMessage = async () => {
    if (chatId && api)
      try {
        await unsaveMessage(api, chatId, messageIds);
      } catch (error: any) {
        notify(error.message, "error");
      }
  };

  const fetchMoreMessages = () => {
    setQueryParams((prevParams) => ({
      ...prevParams,
      pageNumber: prevParams.pageNumber + 1,
    }));
  };

  const handleClickClose = () => {
    setShowModal(false);
  };

  const handleSelectMessage = () => {
    handleClose();
    setShowCheckbox(true);
  };

  const handleCancel = () => {
    setShowCheckbox(false);
    setMessageIds([]);
    setSavedMessage(false);

    if (showModal) {
      handleClickClose();
    }
  };

  const onPreviewClose = () => {
    setFile(null);
    setMediaPreview(false);
  };

  const fetchMessages = async () => {
    if (chatId && api)
      try {
        const res = await getMessage(api, chatId, queryParams);

        if (res) {
          setMessageList((prevMessageList) =>
            queryParams.pageNumber === 1
              ? res.result
              : [...prevMessageList, ...res.result]
          );

          if (res.result.length === 0) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        }
      } catch (error: any) {
        notify(error.message, "error");
      }
  };

  const handleFileUploadProgress = (uploadedBytes: number) => {
    setTestProgress(uploadedBytes);
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    messageId: string
  ) => {
    setSavedMessage(false);

    if (e.target.checked === true) {
      setMessageIds([...messageIds, messageId]);
    } else {
      setMessageIds(messageIds.filter((item) => item !== messageId));
    }
  };

  const getIcon = (text: string) => {
    if (text === "Budget and timeline agreed") {
      return budgetIcon;
    } else if (text === "Update on budget and timeline") {
      return updateIcon;
    } else {
      return pendingIcon;
    }
  };

  const getProjectDetails = async (projectId: string) => {
    if (!api) return;
    try {
      const res = await getSingleProject({ api, projectId });
      if (res) {
        setProjectDetails(res);
      }
    } catch (error: any) {
      notify(error.message, "error");
    }
  };

  const handleSaveAction = () => {
    setSavedMessage(!savedMessage);

    if (savedMessage) {
      storeMessage();
    } else {
      unStoreMessage();
    }
  };

  const validation = () => {
    return (
      chatPayload.text !== "" ||
      (chatPayload.fileUri !== "" && chatPayload.fileUri)
    );
  };

  const handleOnChatSubmit = async () => {
    const isValid = validation();
    if (!isValid || !api) {
      return;
    }
    const payload = { ...chatPayload };

    setMediaPreview(false);
    setChatPayload(emptyMessage);
    setIsLoadingIcon(true);
    if (textInputRef?.current) {
      textInputRef.current.value = "";
    }

    payload.nonce = `${Date.now().toString(16)}`;
    // add message item to list before sending to BE
    setMessageList([
      {
        messageId: "",
        projectId: projectDetails?.projectId ?? "",
        text: payload.text ?? "",
        sentBy: "user",
        messageType: "chat",
        projectStatus: "",
        fileType: file?.type ?? "",
        fileUri: payload.fileUri ?? "",
        fileThumbnailUri: payload.fileUri ?? "",
        timeStamp: Date(),
        saved: false,
        replyTo: payload.replyTo ?? "",
        nonce: payload.nonce ?? "",
      },
      ...messageList,
    ]);

    // upload file if available
    if (file && api) {
      const uploadTokens = await getUploadTokens(api);
      if (uploadTokens) {
        var uploadRes = await uploadChunksToStorage(
          uploadTokens.accountUrl + uploadTokens.projectFileContainerToken,
          uploadTokens.projectFileContainerName,
          file.name,
          file,
          (num: number) => {} // replace with loader
        );
        if (uploadRes) {
          payload.fileUri = uploadRes.uri;
        }
      }
    }
    var result = await sendMessage(
      api,
      projectDetails?.projectId ?? "",
      payload
    );
    if (result) {
      // find and update message with the same nonce
      const messages = messageListRef.current;
      messages.forEach((item) => {
        if (item.nonce === result.nonce) item.messageId = result.messageId;
      });
      setMessageList(messages);
      console.log(messages);
    }
  };

  const removeMessage = async () => {
    if (chatId && api)
      try {
        await deleteMessage(api, chatId, messageIds);
        setShowCheckbox(false);
        setMessageList(
          messageList.filter(
            (message) => !messageIds.includes(message.messageId)
          )
        );
        handleCancel();
      } catch (error: any) {
        notify(error.message, "error");
      }
  };

  useEffect(() => {
    chatId && getProjectDetails(chatId);
  }, [api]);

  useEffect(() => {
    chatId && api && fetchMessages();
  }, [api, queryParams]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTestProgress((prev) => prev + 20);
      if (testProgress >= 100) {
        setIsLoadingBar(false);
        clearInterval(interval);
      }
    }, 0.000000000000000001);
  }, [isLoadingBar]);

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleOnChatSubmit();
    }
  };

  const getPlatform = (key: string) => {
    return (
      lookup?.ProjectSolutionPlatform.find((item) => item.key == key)
        ?.description ?? ""
    );
  };
  const getCategory = (key: string) => {
    return (
      lookup?.ProjectCategory.find((item) => item.key == key)?.description ?? ""
    );
  };
  const getLanguage = (key: string) => {
    return (
      lookup?.ProjectSolutionLanguage.find((item) => item.key == key)
        ?.description ?? ""
    );
  };
  const getTimeline = (key: string) => {
    return (
      lookup?.ProjectTimeline.find((item) => item.key == key)?.description ?? ""
    );
  };

  const getStatusIcon = (status: string, color: string) => {
    if (status === "inreview") {
      return <Visibility sx={{ color: color }} />;
    } else if (status === "pending") {
      return <Pending sx={{ color: color }} />;
    } else if (status === "develop") {
      return <Construction sx={{ color: color }} />;
    } else {
      return <TaskAltRounded sx={{ color: color }} />;
    }
  };
  return (
    <>
      {mediaPreview ? (
        <MediaPreview
          close={onPreviewClose}
          onSubmit={handleOnChatSubmit}
          showTextBox={true}
          file={file}
          onTextChange={handleOnTextChange}
          text={chatPayload.text}
        />
      ) : (
        <div>
          <Header>
            <div
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "100%",
                backgroundColor: getStatusColors(projectDetails?.status ?? "")
                  .bgColor,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {getStatusIcon(
                projectDetails?.status ?? "",
                getStatusColors(projectDetails?.status ?? "")?.color
              )}
            </div>
            <Deets>
              <div>{projectDetails?.name}</div>
              <div>
                {getPlatform(projectDetails?.platform ?? "")} •{" "}
                {getLanguage(projectDetails?.language ?? "")}
              </div>
            </Deets>
            <div className="icons">
              <img
                src={phoneIcon}
                alt=""
                className="phone"
                onClick={() =>
                  navigate("/calls/schedule-a-call", { state: chatId })
                }
              />
            </div>
          </Header>
          <Wrapper
            id="scrollableDiv"
            style={{
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            <InfiniteScroll
              dataLength={messageList.length}
              hasMore={hasMore}
              loader={
                <div className="loader">
                  <CircularProgress color="inherit" size={20} disableShrink />
                </div>
              }
              endMessage={<div style={{ paddingTop: "5px" }} />}
              next={fetchMoreMessages}
              style={{
                display: "flex",
                flexDirection: "column-reverse",
                rowGap: "2px",
              }}
              inverse={true}
              scrollableTarget="scrollableDiv"
            >
              {messageList.map((item, index) => (
                <article
                  className={item.sentBy === "user" ? "me_article" : undefined}
                >
                  {showCheckbox ? (
                    <Checkbox
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                      onChange={(e) => handleChange(e, item.messageId)}
                    />
                  ) : (
                    <div />
                  )}

                  <div key={index} className="message_container">
                    {item.messageType === "chat" ? (
                      <div
                        className="message_content"
                        id={item.sentBy === "user" ? "me" : "algocipher"}
                      >
                        {item?.fileThumbnailUri &&
                          item?.fileThumbnailUri !== "" && (
                            <img src={item?.fileThumbnailUri.split("?")[0]} />
                          )}
                        <div>{item.text}</div>
                        <div className="time">
                          {item.messageId == "" ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : (
                            getTimeFromISO(item.timeStamp)
                          )}
                        </div>
                      </div>
                    ) : (
                      <>
                        {item.text && (
                          <div className="icon_text">
                            <img src={getIcon(item.text)} />
                            <div>{item.text}</div>
                          </div>
                        )}
                        <div
                          id={item.sentBy === "user" ? "me" : "algocipher"}
                          className="message_type"
                        >
                          <div>
                            <img src={projectIcon} />

                            <div>
                              <div className="name">{projectDetails?.name}</div>
                              <div className="cl">
                                <>{projectDetails?.category} </>
                                <div />
                                <>{projectDetails?.language}</>
                              </div>
                              <div className="time">
                                {changeDateFormatToTimelineFormat(
                                  projectDetails?.createdAt
                                )}
                              </div>
                            </div>
                          </div>

                          <div>
                            <div
                              className="status"
                              style={{
                                color: getStatusColors(item.projectStatus ?? "")
                                  .color,
                                border: `1px solid ${
                                  getStatusColors(item.projectStatus ?? "")
                                    .color
                                }`,
                              }}
                            >
                              <>
                                {
                                  getStatusColors(item.projectStatus ?? "")
                                    .status
                                }
                              </>

                              <div
                                style={{
                                  backgroundColor: getStatusColors(
                                    item.projectStatus ?? ""
                                  ).color,
                                }}
                              />
                            </div>

                            <div
                              className="status project"
                              onClick={() =>
                                navigate(`/projects/${item.projectId}`, {
                                  state: item.projectId,
                                })
                              }
                            >
                              Tap to view project
                            </div>
                          </div>

                          <div className="time">
                            {getTimeFromISO(item.timeStamp)}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </article>
              ))}
            </InfiniteScroll>
          </Wrapper>
          <InputContainer>
            {showCheckbox ? (
              <article>
                <img src={closeButton} onClick={handleCancel} />
                <div>{messageIds.length} Selected</div>
                <img
                  src={
                    messageIds.length < 1
                      ? inactiveSave
                      : savedMessage
                      ? savedMessageIcon
                      : activeSave
                  }
                  onClick={() => messageIds.length > 0 && handleSaveAction()}
                />
                <img
                  src={messageIds.length > 0 ? activeDelete : inactiveDelete}
                  onClick={handleClickOpen}
                />
              </article>
            ) : (
              <>
                <textarea
                  placeholder="Write a message"
                  value={chatPayload.text}
                  onChange={(e) => handleOnTextChange(e.target.value)}
                  onKeyDown={handleInputKeyDown}
                  ref={textInputRef}
                />

                <FilePicker
                  disabled={false}
                  onSelected={handleOnFileSelected}
                  allowedTypes={allowedFileTypes}
                  buttonImg={folder}
                  buttonStyle={filePickerStyles}
                  flexDirection="column-reverse"
                  bottom="160%"
                  left="-120%"
                />

                {chatPayload.text?.trim() && (
                  <div className="btn" onClick={handleOnChatSubmit}>
                    {messageLoader ? (
                      <CircularProgress
                        size={25}
                        sx={{
                          color: "#117000",
                          position: "absolute",
                          top: "35%",
                          transform: "translateY(-35%)",
                          right: "40px",
                          "@media (min-width: 992px) and (max-width: 1300px)": {
                            right: "30px",
                          },
                          "@media (max-width: 991px)": {
                            right: "15px",
                          },
                        }}
                        disableShrink
                      />
                    ) : (
                      <img src={GreenButton} className="green_btn" />
                    )}
                  </div>
                )}
              </>
            )}
          </InputContainer>
          <Dialog
            open={showModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClickClose}
            aria-describedby="alert-dialog-slide-description"
            className="chat-page_modal"
          >
            <DialogTitle
              sx={{
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "30px",
                fontFamily: "Jost",
                color: "#000000",
                textAlign: "center",
              }}
            >
              {"Are you sure you want to delete message(s)?"}
            </DialogTitle>
            <DialogActions>
              <CancelBtn onClick={handleClickClose}>Cancel</CancelBtn>
              <DeleteBtn
                onClick={() => messageIds.length > 0 && removeMessage()}
              >
                Delete Message
              </DeleteBtn>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default ChatRoom;
