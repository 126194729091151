import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserImage from "../../components/UserImage";
import InactiveChatIcon from "../../assets/icons/inactive-chat.svg";
import {
  Header,
  Hr,
  Container,
  Card,
  TextContainer,
  Text1,
  Text2,
  Empty,
  EmptyContent,
  Text,
} from "./style";
import { IChat } from "../../types/chat";
import { getChat } from "../../services/api/chatApi";
import useApi from "../../hooks/api/useApi";
import useNotification from "../../hooks/useNotification";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@mui/material";
import { getTimeFromISO } from "../../services/helpers/useChangeDateFormat";

// assets
import emptyCart from "../../assets/icons/empty-cart.svg";
import { AppContext } from "../../context";
import { getStatusColors } from "../../services/helpers/helpers";
import {
  Construction,
  Pending,
  TaskAltRounded,
  Visibility,
} from "@mui/icons-material";

export const getStatusIcon = (status: string, color: string) => {
  if (status === "inreview") {
    return <Visibility sx={{ color: color }} />;
  } else if (status === "pending") {
    return <Pending sx={{ color: color }} />;
  } else if (status === "develop") {
    return <Construction sx={{ color: color }} />;
  } else {
    return <TaskAltRounded sx={{ color: color }} />;
  }
};

const Chat = () => {
  const navigate = useNavigate();
  const { notify } = useNotification();
  const [activeChats, setActiveChat] = useState<IChat[]>([]);
  const [queryParams, setQueryParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    sortOrder: "asc",
  });
  const { api } = useContext(AppContext);

  const handleChatClick = (itemId: string) => {
    navigate(`/chat/${itemId}`);
  };

  const fetchChatData = async () => {
    if (!api) return;
    try {
      const res = await getChat(api, queryParams);
      res && setActiveChat(res.result);
    } catch (error: any) {
      notify(
        "Error fetching your messages, Please try again or contact support if issue persists",
        "error"
      );
    }
  };

  const fetchMoreData = () => {};

  useEffect(() => {
    api && fetchChatData();
  }, [api, queryParams]);

  return (
    <Container>
      {activeChats.length === 0 ? (
        <Empty>
          <EmptyContent>
            <img src={emptyCart} alt="empty-cart" />
            <Text style={{ fontWeight: 500 }}>No messages yet</Text>
          </EmptyContent>
        </Empty>
      ) : (
        <InfiniteScroll
          dataLength={activeChats.length}
          hasMore={true}
          loader={
            <div className="loader">
              <CircularProgress
                style={{}}
                color="inherit"
                size={20}
                disableShrink
              />
            </div>
          }
          next={fetchMoreData}
        >
          {activeChats.map((item) => {
            const status = getStatusColors(item.projectStatus);
            return (
              <Card
                key={item.projectId}
                onClick={() => handleChatClick(item.projectId)}
              >
                <div
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "100%",
                    backgroundColor: status.bgColor,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {getStatusIcon(item.projectStatus, status.color)}
                </div>

                <TextContainer>
                  <Text1>{item.projectName}</Text1>
                  <Text2>
                    {item.lastMessageText?.length > 50
                      ? item.lastMessageText.substring(0, 50) + "..."
                      : item.lastMessageText}
                  </Text2>
                </TextContainer>

                <div className="time">
                  <Text2
                    style={{
                      color: "var(--GreyscaleBorderDarker)",
                    }}
                  >
                    {getTimeFromISO(item.lastMessageTimeStamp)}
                  </Text2>
                </div>
              </Card>
            );
          })}
        </InfiniteScroll>
      )}
    </Container>
  );
};

export default Chat;
