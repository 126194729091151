import Prompts from "../../components/Prompts";
import { ILookUp } from "../../types/lookup";
import { Grid, Order, Section, Select, Tabs, Text } from "./create-project/style1";
import { useState, useEffect } from "react";

interface ISelectProjectDetail {
  title: string;
  lookUp?: ILookUp[];
  grid?: boolean;
  onSelect?: (option: ILookUp) => void;
  initialSelectedKey?: string;
}
const SelectProjectDetail = ({
  title,
  lookUp,
  grid = true,
  onSelect,
  initialSelectedKey
}: ISelectProjectDetail) => {
  const [selectedOption, setSelectedOption] = useState<ILookUp>();
  const isLocked = !!initialSelectedKey;

  const lookups = () => {
    const sortedLookup: ILookUp[] = [];
    let other: ILookUp | undefined = undefined;
    lookUp?.forEach((item, index) => {
      if (item.key === "other") {
        other = item;
      } else {
        sortedLookup.push(item);
      }
    });
    if (other) {
      sortedLookup.push(other);
    }
    return sortedLookup;
  };

  const handleOptionSelected = (option: ILookUp) => {
    if (!isLocked && onSelect) {
      setSelectedOption(option);
      onSelect(option);
    }
  };

  useEffect(() => {
    if (initialSelectedKey) {
      const selected = lookups().find(item => item.key === initialSelectedKey);
      setSelectedOption(selected);
    }
  }, [initialSelectedKey]);

  return (
    <>
      <Prompts title={title} />
      {grid ? (
        <Grid>
          {lookups()?.map((item: ILookUp, index: number) => (
            <Order key={index}>
              <label className="container">
                <Text>{item.description}</Text>
                <input
                  type="radio"
                  name="radioGroup"
                  value={item.id}
                  id={item.id.toString()}
                  checked={selectedOption === item}
                  onChange={(e) => handleOptionSelected(item)}
                />
                <span className="checkmark"></span>
              </label>
            </Order>
          ))}
        </Grid>
      ) : (
        <Tabs>
          {lookups()?.map((item: any, index: number) => (
            <Select
              key={index}
              style={{
                backgroundColor:
                  selectedOption === item
                    ? "var(--PrimarySurfaceDefault)"
                    : undefined,
                color:
                  selectedOption === item
                    ? "#FFF"
                    : "var(--GreyscaleTextIconBody)",
                padding: selectedOption === item ? "6px 16px" : undefined,
              }}
              onClick={() => handleOptionSelected(item)}
            >
              {item.description}
            </Select>
          ))}
        </Tabs>
      )}
    </>
  );
};
export default SelectProjectDetail;
