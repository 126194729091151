import React, { useContext, useEffect, useState } from "react";
import Prompts from "../../../components/Prompts";
import CustomInput from "../../../components/Input";
import CustomSelect from "../../../components/Select";
import Button from "../../../components/Button";
import { X } from "phosphor-react";
import {
  Container,
  Header,
  Section,
  TimePicker,
  Textarea,
  Spacer,
} from "./schedule-a-call-style";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import useNotification from "../../../hooks/useNotification";
import {
  getAvailableCallDate,
  getAvailableCallTime,
  scheduleCall,
} from "../../../services/api/callApi";
import { ICalendar } from "../../../types/call";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import {
  changeDateFormatFromString,
  getTimeFromISO,
} from "../../../services/helpers/useChangeDateFormat";
import { IProjectId } from "../../../types/project";
import { getUserProjectIds } from "../../../services/api/projectApi";
import { useLocation, useNavigate } from "react-router-dom";

// assets
import blackCalendar from "../../../assets/icons/black-calendar.svg";
import whiteCalendar from "../../../assets/icons/calendar.svg";
import { AppContext } from "../../../context";

interface formInputProp {
  title: string;
  project: string;
  description: string;
}

const ScheduleACall = () => {
  const [date, setDate] = useState("");
  const [value, onChange] = useState<any>();
  const { notify } = useNotification();
  const [availableDates, setAvailableDates] = useState<ICalendar[]>();
  const [availableTime, setAvailableTime] = useState<any>([]);
  const [project, setProject] = useState<IProjectId[]>();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { api } = useContext(AppContext);
  const chatProjectId = project?.find(
    (item) => item.projectId === location.state
  )?.projectId;
  const [formInput, setFormInput] = useState<formInputProp>({
    title: "",
    description: "",
    project: ""
  });
  
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormInput((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCancelSchedule = () => {
    navigate(-1);
  };

  const pickDates = (dates: ICalendar[]) => {
    if (dates.length === 0) {
      return {
        minDate: undefined,
        maxDate: undefined,
        tileDisabled: () => true,
      };
    }
    const firstMonth = dates[0];
    const lastMonth = dates[dates.length - 1];

    const minDate = new Date(
      firstMonth.year,
      firstMonth.month - 1,
      firstMonth.days[0]
    );
    const maxDate = new Date(
      lastMonth.year,
      lastMonth.month - 1,
      lastMonth.days[lastMonth.days.length - 1]
    );

    return { minDate, maxDate };
  };

  const handleSelectedDate = (selectedDate: string) => {
    if (selectedDate === date)
      return {
        bgColor: "var(--PrimarySurfaceDefault)",
        color: "#FFF",
        fontWeight: 500,
      };
  };

  const fetchProjects = async () => {
    if (!api) return;
    try {
      const res = await getUserProjectIds(api);
      if (res) {
        setProject(res);
      }
    } catch (error: any) {
      notify(error.message, "error");
    }
  };

  const fetchAvailableDates = async () => {
    if (!api) return;
    try {
      const res = await getAvailableCallDate(api);
      if (res) {
        setAvailableDates(res);
      }
    } catch (error: any) {
      notify(error.message, "error");
    }
  };

  const fetchAvailableTime = async () => {
    if (!api) return;
    try {
      const res = await getAvailableCallTime(
        api,
        changeDateFormatFromString(value)
      );
      console.log(res);
      if (res) {
        setAvailableTime(res);
      }
    } catch (error: any) {
      notify(error.message, "error");
    }
  };

  const toLocalTime = (time: string) => {
    const utcDate = new Date(`${time}Z`);
    time = utcDate.toLocaleString();
    console.log(time);

    return time;
  };

  const handleScheduleCall = async () => {
    console.log(formInput);
    if (
      Object.values(formInput).some((value) => value === "") ||
      date == null ||
      value === undefined
    ) {
      notify("Please fill out all the details", "error");
      return;
    }

    if (project) {
      setIsLoading(true);
      const payload = {
        meetingName: formInput.title,
        description: formInput.description,
        projectId: formInput.project,
        time: date,
      };

      try {
        api && (await scheduleCall(api, payload));
        notify("Call scheduled successfully", "success");
        navigate(-1);
      } catch (error: any) {
        notify(error.message, "error");
      } finally {
        setIsLoading(false)
      }
    } else {
      notify("Something went wrong. Try again later", "error");
    }
  };

  useEffect(() => {
    if (api) {
      fetchProjects();
      fetchAvailableDates();
    }
  }, [api]);

  useEffect(()=>{
    if (chatProjectId) {
      setFormInput((prev)=> ({
        ...prev,
        project: chatProjectId
      }))
    }
  },[chatProjectId])

  useEffect(() => {
    if (value && api) fetchAvailableTime();
  }, [value, api]);

  return (
    <Container>
      <Header>
        <div>
          <img src={blackCalendar} />
          <h4>Schedule a call</h4>
        </div>
        <p>Schedule a call between you and Algocipher</p>
      </Header>

      <Section>
        <Prompts
          color="var(--GreyscaleTextIconBody)"
          title="Add a title for the meeting"
        />
        <CustomInput
          placeholder="Enter title"
          value={formInput.title}
          name="title"
          type="text"
          onChange={handleChange}
        />
      </Section>

      <Section>
        <Prompts
          color="var(--GreyscaleTextIconBody)"
          title="Select a project associated with this call"
        />
        <CustomSelect
          value={formInput.project}
          size="size"
          name="project"
          handleChange={handleChange}
          data={
            project?.map((item) => {
              return { title: item.projectName, value: item.projectId };
            }) ?? []
          }
        />
      </Section>

      <Section className="date-time-container">
        <Prompts
          color="var(--GreyscaleTextIconBody)"
          bold
          title="Scheduled date and time for call"
        />
        <div className="p-text">
          You will receive a notification, both via email and within the app, 10
          minutes before the scheduled call.
        </div>

        <article>
          <>
            {availableDates && availableDates.length !== 0 ? (
              <Calendar
                value={value}
                onChange={onChange}
                activeStartDate={undefined}
                minDate={pickDates(availableDates).minDate}
                maxDate={pickDates(availableDates).maxDate}
                tileDisabled={pickDates(availableDates).tileDisabled}
              />
            ) : (
              <Loader />
            )}
          </>

          <>
            {value ? (
              availableTime && availableTime.length !== 0 ? (
                <TimePicker>
                  {availableTime.map((item: any) => {
                    const newTime = toLocalTime(item)
                    return (
                      <div
                        key={item}
                        onClick={() => setDate(item)}
                        style={{
                          backgroundColor: handleSelectedDate(item)?.bgColor,
                          color: handleSelectedDate(item)?.color,
                          fontWeight: handleSelectedDate(item)?.fontWeight,
                        }}
                      >
                        {getTimeFromISO(newTime)}
                      </div>
                    );
                  })}
                </TimePicker>
              ) : (
                <Loader />
              )
            ) : (
              <></>
            )}
          </>
        </article>
      </Section>

      <Section>
        <Prompts
          color="var(--GreyscaleTextIconBody)"
          title="Project description"
        />
        <Textarea
          placeholder="Enter project description"
          rows={6}
          value={formInput.description}
          name="description"
          onChange={handleChange}
        />
      </Section>

      <Spacer>
        <Button
          title="Schedule call"
          bg="var(--PrimaryBorderLighter)"
          color="var(--GreyscaleSurfaceSubtle)"
          w="48%"
          budget
          iconRight
          isLoading={isLoading}
          disabled={isLoading}
          icon={<img src={whiteCalendar} />}
          onClick={() => handleScheduleCall()}
        />

        <Button
          title="Cancel schedule"
          bg="var(--Grey100)"
          color="var(--GreyscaleTextIconSubtitle)"
          w="48%"
          budget
          iconRight
          icon={<X size={20} weight="bold" color="#494949" />}
          onClick={() => handleCancelSchedule()}
        />
      </Spacer>
    </Container>
  );
};

export default ScheduleACall;

const Loader = () => {
  return (
    <Box sx={{ width: 348 }}>
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
    </Box>
  );
};
