import { Lookups } from "../../types/lookup";
import { IProposals } from "../../types/project";

export const formatTime = (time: any): string => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  return `${minutes}:${formattedSeconds}`;
};

export const getOrdinalSuffix = (day: any) => {
  if (day >= 11 && day <= 13) {
    return "th";
  }
  const lastDigit = day % 10;
  if (lastDigit === 1) {
    return "st";
  } else if (lastDigit === 2) {
    return "nd";
  } else if (lastDigit === 3) {
    return "rd";
  } else {
    return "th";
  }
};

export const formatDate = (date: any) => {
  const options: any = { weekday: "long", day: "numeric", month: "long" };
  const [weekday, day, month] = new Date(date)
    .toLocaleDateString("en-US", options)
    .split(" ");
  const ordinalSuffix = getOrdinalSuffix(month);

  return `${weekday} ${month}${ordinalSuffix} ${day} ${date.getFullYear()}`;
};

export const bytesToMegaBytes = (bytes: number): number => {
  return bytes / (1024 * 1024);
};


// getFileType helper
export const getFileType = (doc: string, lookup?: Lookups) => {
  const fileTypesObj = lookup?.FileType;

  if (doc?.includes("image")) {
    return fileTypesObj?.find((x)=>x.key === "photo")?.id
  } else if (doc?.includes("video")) {
    return fileTypesObj?.find((x)=>x.key === "video")?.id
  }
  else return fileTypesObj?.find((x)=>x.key === "document")?.id;
}

// get status' colors
export const getStatusColors = (status: string) => {
  if (status === "inreview") {
    return {
      status: "In Review",
      bgColor: "rgba(244, 52, 136, 0.20)",
      color: "#F43488",
    };
  } else if (status === "pending") {
    return {
      status: "Pending",
      bgColor: "rgba(252, 166, 37, 0.20)",
      color: "#FCA625",
    };
  } else if (status === "develop") {
    return {
      status: "Development",
      bgColor: "rgba(21, 113, 200, 0.20)",
      color: "#1571C8",
    };
  } else {
    return {
      status: "Done",
      bgColor: "rgba(121, 255, 97, 0.20)",
      color: "#0E5C00",
    };
  }
};

export const getProposalValues = (item: IProposals) => {
  const { reply, cost, timeline, submittedAt } = item;

  return {
    submittedAt: reply?.submittedAt || submittedAt,
    cost: reply?.cost || cost,
    timeline: reply?.timeline || timeline,
    budgetFrom: reply?.budgetFrom || cost,
    budgetTo: reply?.budgetTo || cost,
  }
}

