import { ArrowRight } from 'phosphor-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import { 
  Wrapper,
  Container,
  Flex,
  Col,
  Spacer,
  Header,
  Text1,
  SubHeader,
  Text2,
  Text3 
} from './style';

// assets
import confirmOrder from "../../../assets/images/successful.svg";

interface ReceiptProps {
  img: string,
  header: string,
  text: string,
  date: string,
  cost: number,
  referenceId: string,
  paymentMethod: string,
  iconType: string,
  icon: React.ReactNode
  buttonText: string
}

const DevelopmentTab = ({ img, header, text, date, cost, referenceId, paymentMethod, iconType, icon, buttonText }: ReceiptProps) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Container>
        <img src={img} alt="confirm" />
        <Header>{header}</Header>
        <Text1>{text}</Text1>

        <SubHeader>Transaction summary</SubHeader>

        <Flex>
          <Col>
            <Text2>Date:</Text2>
            <Text2>Amount paid:</Text2>
            <Text2>Reference ID:</Text2>
            <Text2>Transaction type:</Text2>
            <Text2>Payment method:</Text2>
            <Text2>Payment to:</Text2>
          </Col>

          <Col className='flex-end'>
            <Text3>{date}</Text3>
            <Text3>${cost}</Text3>
            <Text3>{referenceId}</Text3>
            <Text3>Payment</Text3>
            <Text3>Card transaction{paymentMethod}</Text3>
            <Text3>Algocipher Quantitative LLC</Text3>
          </Col>
        </Flex>

        <Spacer>
          <Button 
            title={buttonText}
            bg="var(--PrimaryBorderLighter)"
            color='#FFF'
            iconRight={iconType === "right"}
            icon={icon}
            onClick={()=>{
              navigate("/projects")
            }}
          />
        </Spacer>
      </Container>
    </Wrapper>
  )
}

export default DevelopmentTab

