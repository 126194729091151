import CustomInput from "../../components/Input";
import { CaretRight, Circle } from "phosphor-react";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import CustomSelect from "../../components/Select";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { AppContext } from "../../context";
import useApi from "../../hooks/api/useApi";
import CustomButton from "../../components/Authentication/custom-buttons";
import { IUser, UpdateUserPayload } from "../../types/user";
import useNotification from "../../hooks/useNotification";
import { putMe } from "../../services/api/userApi";
import Tooltip from "@mui/material/Tooltip";
import { Country, State, City } from "country-state-city";
import {
  getUploadTokens,
  uploadChunksToStorage,
} from "../../hooks/api/useStorage";
import {
  Flex,
  Header,
  Hr,
  Text,
  SubFlex,
  VStack,
  Placeholder,
  IContainer,
  Textarea,
  Tab,
  Tabheader,
  Children,
} from "./style";

// assets
import profile from "../../assets/icons/user-icon.svg";
import exportBtn from "../../assets/icons/export-btn.svg";
import countryIcon from "../../assets/icons/country-icon.svg";
import messageIcon from "../../assets/icons/message-icon.svg";
import phoneIcon from "../../assets/icons/phone-icon.svg";
import { Avatar, Button } from "@mui/material";
import { useMsal } from "@azure/msal-react";

interface CountryDataProps {
  title: string;
  value: string;
  isoCode: string;
}

const Settings = () => {
  const { user, setUser } = useContext(AppContext);
  const { notify } = useNotification();
  const api = useApi();
  const [imageUri, setImageUri] = useState<any>();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    postalcode: "",
    country: "",
    state: "",
    city: "",
    address: "",
    isChecked: false,
  });
  const [loading, setLoading] = useState(false);
  const [testProgress, setTestProgress] = useState(0);
  const { instance, accounts } = useMsal();

  const handleSelection = (value: string, arr: CountryDataProps[]) => {
    const selectValue = arr.find((item) => item.value === value)?.isoCode;

    return selectValue ?? "";
  };

  const countryData = Country.getAllCountries().map((item) => {
    return { title: item.name, value: item.name, isoCode: item.isoCode };
  });

  const stateData = State.getStatesOfCountry(
    handleSelection(formData.country, countryData)
  ).map((item) => {
    return { title: item.name, value: item.name, isoCode: item.isoCode };
  });

  const cityData: any = City.getCitiesOfState(
    handleSelection(formData.country, countryData),
    handleSelection(formData.state, stateData)
  ).map((item) => {
    return { title: item.name, value: item.name };
  });

  const handleImageSize = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];

      const maxSize = 5 * 1024 * 1024;

      if (file && file.size <= maxSize) {
        setImageUri(file);
      } else {
        notify("File exceeds 5mb", "error");
      }
    }
  };

  const handleFileUploadProgress = (uploadedBytes: number) => {
    setTestProgress(uploadedBytes);
  };

  const handleChange = (e: any) => {
    const { name, type, value, checked } = e.target;
    console.log("handleChange", e);
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "isChecked" ? checked : value,
    }));
  };

  const handleSave = async () => {
    if (!api) return;
    setLoading(true);

    try {
      const uploadTokens = await getUploadTokens(api);
      if (!uploadTokens) {
        notify("Something went wrong, please try that again ", "error");
      }
      const data: UpdateUserPayload = {
        firstName: formData.firstname,
        lastName: formData.lastname,
        imageUri: user?.imageUri,
        phone: formData.phone,
        postalCode: formData.postalcode,
        country: formData.country,
        state: formData.state,
        city: formData.city,
        address: formData.address,
        emailNotification: formData.isChecked,
      };

      if (imageUri) {
        try {
          const url =
            uploadTokens.accountUrl + uploadTokens.projectFileContainerToken;
          var uploadRes = await uploadChunksToStorage(
            url,
            uploadTokens.projectFileContainerName,
            imageUri.name,
            imageUri,
            handleFileUploadProgress
          );
          data.imageUri = uploadRes.uri.split("?")[0];
        } catch {}
      }
      await putMe(api, data)
        .then((res: IUser) => {
          setUser(res);
          notify("Profile updated successfully", "success");
        })
        .catch((error) => {
          notify(
            "Error saving profile changes, Please try that again or contact support if issue persists",
            "error"
          );
        });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      setFormData((prevState) => ({
        ...prevState,
        firstname: user.firstName,
        lastname: user.lastName,
        email: user.email,
        phone: user.phone,
        postalcode: user.postalCode,
        country: user.country,
        state: user.state,
        city: user.city,
        address: user.address,
        isChecked: user.emailNotification,
      }));

      setImageUri(user.imageUri);
    }
  }, [user]);

  const logOut = () => {
    instance.logoutRedirect();
  };

  return (
    <div>
      <div>
        <Flex style={{ paddingBottom: "30px" }}>
          <Header>Settings</Header>

          <Tooltip
            title="Please fill out the Name fields"
            open={formData.firstname === "" || formData.lastname === ""}
          >
            <div>
              <CustomButton
                title="Save and exit"
                type="button"
                style={{
                  background: `${
                    formData.firstname === "" ||
                    formData.lastname === "" ||
                    loading
                      ? "#90998F"
                      : "var(--PrimarySurfaceLighter)"
                  }`,
                  color: `${
                    formData.firstname === "" ||
                    formData.lastname === "" ||
                    loading
                      ? "#B5BBB4"
                      : "var(--GreyscaleSurfaceSubtle)"
                  }`,
                  padding: "10px 25px",
                  borderRadius: "100px",
                }}
                isLoading={loading}
                disabled={
                  loading ||
                  formData.firstname === "" ||
                  formData.lastname === ""
                }
                onClick={handleSave}
              />
            </div>
          </Tooltip>
        </Flex>
      </div>

      <SettingsTab header="Personal information">
        <Flex>
          <Text>Full name</Text>

          <SubFlex>
            <CustomInput
              type="text"
              placeholder="First name"
              required
              quarter
              icon={
                <img src={profile} alt="profile" style={{ marginTop: "6px" }} />
              }
              iconPosition={{ left: "10px" }}
              value={formData.firstname}
              name="firstname"
              onChange={handleChange}
              style={{
                padding: "10px 16px 10px 40px",
              }}
            />

            <CustomInput
              type="text"
              placeholder="Last name"
              required
              quarter
              icon={
                <img src={profile} alt="profile" style={{ marginTop: "6px" }} />
              }
              iconPosition={{ left: "10px" }}
              value={formData.lastname}
              name="lastname"
              onChange={handleChange}
              style={{
                padding: "10px 16px 10px 40px",
              }}
            />
          </SubFlex>
        </Flex>
        <Hr />

        <Flex className="flex_start">
          <VStack>
            <Text>Your photo</Text>
            <Text
              style={{
                color: "var(--GreyscaleSurfaceDisabled)",
                fontWeight: 400,
              }}
            >
              This will be displayed on your profile
            </Text>
          </VStack>

          <SubFlex className="flex_start">
            {imageUri ? (
              imageUri instanceof Blob ? (
                <Avatar
                  src={URL.createObjectURL(imageUri)}
                  className="avatar_img"
                  alt=""
                ></Avatar>
              ) : (
                <Avatar src={imageUri} className="avatar_img" alt="" ></Avatar>
              )
            ) : (
              <Placeholder>
                <div>{user?.firstName?.slice(0, 1)}</div>
                <div>{user?.lastName?.slice(0, 1)}</div>
              </Placeholder>
            )}

            <IContainer>
              <input
                type="file"
                accept="image/png, image/jfif, image/svg, image/jpeg, image/webp, image/bmp"
                onChange={handleImageSize}
              />

              <article>
                <img src={exportBtn} alt="" />
                <Text>
                  Click to upload{" "}
                  <span
                    style={{
                      color: "var(--GreyscaleSurfaceDisabled)",
                      fontWeight: 400,
                    }}
                  >
                    or drag and drop <br />
                    SVG, PNG or JPG (Max 800 x 600)
                  </span>
                </Text>
              </article>
            </IContainer>
          </SubFlex>
        </Flex>
      </SettingsTab>
      <Hr />

      <SettingsTab header="Security and contact information">
        <Flex>
          <Text>Email address</Text>

          <SubFlex>
            <CustomInput
              placeholder="Email address"
              type="text"
              half
              settings
              icon={
                <img
                  src={messageIcon}
                  alt="message"
                  style={{ marginTop: "6px" }}
                />
              }
              iconPosition={{ left: "10px" }}
              value={formData.email}
              name="email"
              readOnly
              style={{
                padding: "10px 16px 10px 40px",
              }}
            />
          </SubFlex>
        </Flex>
        <Hr />

        <Flex>
          <Text>Phone number</Text>

          <SubFlex>
            <CustomInput
              placeholder="Phone number"
              type="text"
              half
              settings
              icon={
                <img src={phoneIcon} alt="phone" style={{ marginTop: "6px" }} />
              }
              iconPosition={{ left: "10px" }}
              value={formData.phone}
              name="phone"
              onChange={handleChange}
              style={{
                padding: "10px 16px 10px 40px",
              }}
            />
          </SubFlex>
        </Flex>
        <Hr />
      </SettingsTab>
      <Hr />
      <SettingsTab header="Billing address">
        <Flex>
          <Text>Country</Text>

          <SubFlex>
            <CustomSelect
              value={formData.country}
              name="country"
              handleChange={handleChange}
              size="14px"
              icon={
                <img
                  src={countryIcon}
                  style={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    left: "10px",
                  }}
                  alt="country"
                />
              }
              data={countryData}
              half
              settings
            />
          </SubFlex>
        </Flex>
        <Hr />

        <Flex>
          <Text>Zip / Postal code</Text>

          <SubFlex>
            <CustomInput
              placeholder="Zip / Postal code"
              type="text"
              value={formData.postalcode}
              name="postalcode"
              onChange={handleChange}
              half
              settings
            />
          </SubFlex>
        </Flex>
        <Hr />

        <Flex>
          <Text>State / Province</Text>

          <SubFlex>
            <CustomSelect
              value={formData.state}
              name="state"
              size="14px"
              handleChange={handleChange}
              data={stateData}
              half
              settings
            />
          </SubFlex>
        </Flex>
        <Hr />

        <Flex>
          <Text>City</Text>

          <SubFlex>
            <CustomSelect
              value={formData.city}
              name="city"
              size="14px"
              handleChange={handleChange}
              data={cityData}
              half
              settings
            />
          </SubFlex>
        </Flex>
        <Hr />

        <Flex className="flex_start">
          <Text>Street address</Text>

          <SubFlex>
            <Textarea
              placeholder="Street address"
              rows={6}
              value={formData.address}
              name="address"
              onChange={handleChange}
            />
          </SubFlex>
        </Flex>
      </SettingsTab>
      <Hr />

      <SettingsTab header="Preference">
        <Flex className="flex__row">
          <Text>Email notification</Text>

          <SubFlex className="flex__end">
            <Text style={{ fontWeight: 600 }}>
              {formData.isChecked ? "ON" : "OFF"}
            </Text>

            <label>
              <Toggle
                checked={formData.isChecked}
                name="isChecked"
                className="custom-classname"
                icons={{
                  checked: (
                    <Circle
                      size={20}
                      weight="thin"
                      style={{ paddingBottom: "10px" }}
                      color="var(--GreyscaleBorderDarker)"
                    />
                  ),
                  unchecked: null,
                }}
                onChange={(e: any) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    isChecked: e.target.checked,
                  }))
                }
              />
            </label>
          </SubFlex>
        </Flex>
      </SettingsTab>

      <SettingsTab header="" nsp>
        <Button
          onClick={logOut}
          variant="contained"
          color="error"
          type="button"
          sx={{ borderRadius: "100px" }}
        >
          Log out
        </Button>
      </SettingsTab>
    </div>
  );
};

export default Settings;

interface SettingsTabProps {
  header: string;
  nsp?: boolean;
  children: React.ReactNode;
}

const SettingsTab = ({ header, nsp, children }: SettingsTabProps) => {
  return (
    <>
      <Tab
        style={{
          padding: nsp ? "0px" : "",
        }}
      >
        <Tabheader>{header}</Tabheader>
        <Hr />
      </Tab>

      <Children>{children}</Children>
    </>
  );
};
