import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";

interface CustomSelectProps {
  value: string | number;
  handleChange?: (e: any) => void;
  size?: string;
  icon?: React.ReactNode;
  data: { title: string; value: any }[];
  half?: boolean;
  settings?: boolean;
  [props: string]: any;
}

const CustomSelect = ({
  half,
  icon,
  size,
  settings,
  value,
  data,
  handleChange,
  ...props
}: CustomSelectProps) => {
  return (
    <FormControl
      sx={{
        position: "relative",
        width: `${
          half ? "calc((100vw - 450px) / 2.1)" : "calc(100vw - 450px)"
        }`,
        background: "none",
        borderRadius: "4px",
        "@media (min-width: 992px) and (max-width: 1300px)": {
          width: `${
            half
              ? `calc((100vw - ${settings ? "250px" : "310px"}) / 2.1)`
              : `calc(100vw - ${settings ? "250px" : "310px"})`
          }`,
        },
        "@media (max-width: 991px)": {
          // width: `${half ? `calc((100vw - ${settings ? '35px' : '310px'}))` : `calc(100vw - ${settings ? '35px' : '310px'})`}`
          width: "calc(100vw - 35px)",
          // width: `calc((100vw - ${settings ? '35px' : '310px'}))`
        },
      }}
    >
      {icon && icon}

      <Select
        value={value}
        {...props}
        onChange={handleChange}
        inputProps={{ "aria-label": "Without label" }}
        sx={{
          height: "45px",
          fontSize: size ? size : "14px",
          fontWeight: size ? 500 : 400,
          color: size
            ? "var(--GreyscaleTextIconBody)"
            : "var(--GreyscaleSurfaceDisabled)",
          paddingLeft: icon ? "25px" : 0,
        }}
      >
        {data.map((item, index) => (
          <MenuItem
            key={index}
            value={item.value}
            style={{ fontSize: size ? size : "14px" }}
          >
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
