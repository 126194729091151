import React, { useContext, useEffect, useState } from "react";
import Proposals from "../../../components/Proposals";
import Button from "../../../components/Button";
import { ArrowRight } from "phosphor-react";
import { Wrapper, Flex, VStack, Col, Header, Text, SubHeader } from "./style3";
import {
  getPaymentStatus,
  makePayment,
} from "../../../services/api/projectApi";
import useNotification from "../../../hooks/useNotification";
import { useNavigate, useParams } from "react-router-dom";
import { IGetProject, IPaymentStatus } from "../../../types/project";
import { AppContext } from "../../../context";
import { ArrowLeft } from "phosphor-react";

// assets
import pending from "../../../assets/images/pending.svg"
import DevelopmentTab from "../development/development-tab";

interface PendingPaymentTabProps {
  selectedProject: IGetProject;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  refreshProject?: () => void;
}

const PendingPaymentTab = ({
  selectedProject,
  setIndex,
  refreshProject,
}: PendingPaymentTabProps) => {
  const { projectId } = useParams();
  const { api, lookup, user } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<IPaymentStatus | null>(
    null
  );
  const [refreshInterval, setRefreshInterval] = useState<NodeJS.Timer>();
  const { notify } = useNotification();
  const navigate = useNavigate();

  const getStatus = () => {
    if (api && selectedProject) {
      getPaymentStatus(api, selectedProject.projectId)
        .then((status) => {
          if (status) {
            setPaymentStatus(status);
            if (!refreshInterval) {
              const interval = setInterval(() => {
                refreshProject?.();
              }, 10000);
              setRefreshInterval(interval);
            }
          }
        })
        .catch((err) => {
        
        });
    }
  };

  useEffect(() => {
    return () => {
      if (refreshInterval) {
        clearInterval(refreshInterval);
      }
    };
  }, []);

  useEffect(() => {
    getStatus();
  }, [api, selectedProject]);

  const budget = {
    from: selectedProject.budgetFrom,
    to: selectedProject.budgetTo,
    cost: selectedProject.cost,
  };

  const handlePayNow = async () => {
    try {
      if (projectId && api) {
        setIsLoading(true);
        var res = await makePayment(api, {
          projectId: projectId,
          redirectUrl: `${window.location.origin}/projects/${projectId}`,
        });
        window.location.href = res.checkoutUrl;
      }
    } catch (error: any) {
      notify(
        "Error making payment, Please try that again or contact support if issue persists",
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getTimeline = () => {
    return lookup?.ProjectTimeline.find(
      (item) => item.key == selectedProject.timeline
    )?.description;
  };

  return paymentStatus ? (
    <DevelopmentTab 
      img={pending}
      header="Payment Confirmation"
      text="Your transaction is been reviewed, once completed you will get a receipt in your email"
      cost={paymentStatus.cost}
      referenceId={paymentStatus.referenceId}
      buttonText="Back to Projects"
      icon={<ArrowLeft size={25} weight="thin" color='#FFF' />}
      iconType="left"
      paymentMethod="Debit Card, VISA"
      date=""
    />
    // <div>
    //   <p>Cost: {paymentStatus.cost}</p>
    //   <p>ReferenceId: {paymentStatus.referenceId}</p>
    //   <p>Status: {paymentStatus.completed ? "Completed" : "Processing"}</p>
    // </div>
  ) : (
    <Wrapper>
      <div className="flex_1">
        <VStack>
          <Header>Payment Method</Header>
          <Text>
            In adherence to Algocipher's policy, initiating project work
            necessitates the payment of the total agreed amount. <br />
            This is integral to the commencement of the project and aligns with
            our established procedures to ensure a seamless and efficient
            workflow.
          </Text>
        </VStack>
      </div>
      <Proposals
        budget={{
          from: budget?.from,
          to: budget?.to,
          cost: budget?.cost,
        }}
        timeline={getTimeline()}
      />

      <Col>
        <Flex className="btn_container_1">
          <Button
            title="Cancel Payment"
            bg="var(--GreyscaleSurfaceBtBackground)"
            color="var(--GreyscaleSurfaceSubtle)"
            budget
            w="47%"
            iconRight
            icon={
              <ArrowRight
                size={25}
                weight="thin"
                color="var(--GreyscaleSurfaceSubtle)"
              />
            }
            onClick={() => navigate("/projects")}
          />

          <Button
            title="Pay with Stripe"
            bg="var(--PrimaryBorderLighter)"
            color="#FFF"
            budget
            w="47%"
            iconRight
            isLoading={isLoading}
            icon={<ArrowRight size={25} weight="thin" color="#FFF" />}
            onClick={() => handlePayNow()}
            disabled={isLoading}
          />
        </Flex>
      </Col>
    </Wrapper>
  );
};

export default PendingPaymentTab;
