import styled from "@emotion/styled";

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 992px) and (max-width: 1300px) {
    margin-right: 10px;
  }
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }
`;
export const SubFlex = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  width: calc((100vw - 450px) / 2.074);

  @media (min-width: 992px) and (max-width: 1300px) {
    width: calc((100vw - 250px) / 2.1);
  }
  @media (max-width: 991px) {
    width: calc(100vw - 35px);
  }
`;
export const VStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const Header = styled.div`
  color: var(--GreyscaleTextIconBody);
  font-size: 30px;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -1.8px;

  @media (min-width: 992px) and (max-width: 1300px) {
    font-size: 28px;
  }
`;
export const Text = styled.div`
  color: var(--GreyscaleTextIconBody);
  line-height: 150%;
  font-size: 14px;
  font-weight: 500;
`;
export const Hr = styled.hr`
  border: 1px solid var(--GreyscaleSurfaceDefault);
  margin: 15px -40px;

  @media (min-width: 992px) and (max-width: 1300px) {
    margin: 15px -20px;
  }
  @media (max-width: 991px) {
    margin: 15px -15px;
  }
`;
export const Tab = styled.section`
  margin: -15px -40px;
  padding: 15px 40px;
  background-color: var(--Grey100);

  > hr {
    margin-bottom: -15px;
    border: 1px solid var(--GreyscaleSurfaceDefault);
  }

  @media (min-width: 992px) and (max-width: 1300px) {
    margin: -15px -20px;
    padding: 15px 20px;
  }
  @media (max-width: 991px) {
    margin: -15px -15px;
    padding: 15px 15px;
  }
`;
export const Tabheader = styled.div`
  font-size: 20px;
  color: var(--GreyscaleTextIconSubtitle);
  font-weight: 600;
`;
export const Children = styled.section`
  margin: 15px -40px;
  padding: 15px 40px 0px;
  overflow-x: hidden;

  > hr {
    border: 1px solid var(--GreyscaleBorderDisabled);
  }
  .flex_start {
    align-items: flex-start;
  }
  .avatar_img {
    width: 68px;
    height: 68px;
    border-radius: 100%;
  }
  .arrow_container {
    background: var(--Grey100);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 50px;
    cursor: pointer;
  }
  .react-toggle-track {
    background-color: var(--Grey100) !important;
    border: 1px solid var(--GreyscaleSurfaceDefault) !important;
  }
  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: var(--Grey100) !important;
  }
  .react-toggle-thumb {
    background-color: #297dfd !important;
    border: none !important;
  }
  .react-toggle--focus .react-toggle-thumb,
  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    box-shadow: none !important;
  }
  .flex__row {
    padding-bottom: 10px;
  }

  @media (min-width: 992px) and (max-width: 1300px) {
    margin: 15px -20px;
    padding: 15px 20px 0px;
  }
  @media (max-width: 991px) {
    margin: 15px -15px 0px;
    padding: 15px 15px 0px;

    .flex__row {
      flex-direction: row;
      align-items: center;
      width: calc(100vw - 35px);

      > div:nth-of-type(1) {
        width: 100%;
      }
    }
    .flex__end {
      display: contents;

      > label {
        margin-left: 10px;
        margin-top: 5px;
      }
    }
  }
`;
export const IContainer = styled.section`
  position: relative;
  border: 1px solid var(--Grey200);
  padding: 20px;
  border-radius: 4px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  > input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  > article {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;

    @media (max-width: 991px) {
      > div {
        text-align: center;
      }
    }
  }
`;
export const Textarea = styled.textarea`
  color: var(--GreyscaleTextIconBody);
  font-size: 14px;
  font-weight: 500 !important;
  line-height: 150%;
  border: 1.5px solid var(--Grey200);
  background: none;
  border-radius: 4px;
  padding: 10px 16px !important;
  width: calc((100vw - 250px) / 2.1);

  ::placeholder {
    color: var(--GreyscaleSurfaceDisabled);
    font-weight: 400;
  }

  @media (max-width: 991px) {
    width: calc(100vw - 35px);
  }
`;
export const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 3px;
  align-items: center;
  width: 64px;
  height: 64px;
  background: var(--GreyscaleSurfaceDefault);
  border-radius: 100%;
  cursor: pointer;
`;

export const LogoutButton = styled.button`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 24px;
gap: 8px;
width: 82px;
height: 40px;
background: #1DC200;
border-radius: 100px;
border: none;
flex: none;
order: 1;
flex-grow: 0;
font-family: Jost;
font-style: normal;
font-weight: 700;
font-size: 14px;
color:#FFFFFF;
cursor: pointer;
float: right;
margin-top: 2%;
`