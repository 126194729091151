import React, { useContext } from 'react'
import { IGetProject } from '../../../types/project';
import Prompts from "../../../components/Prompts";
import CustomInput from "../../../components/Input";
import CustomSelect from '../../../components/Select';
import SelectProjectDetail from '../select-project-detail';
import { AppContext } from '../../../context';
import { OthersTextBoxStyle } from '../create-project/create-project-tab';
import {
  Wrapper,
  Flex,
  VStack,
  Header,
  Text,
  Section,
  Textarea,
  OthersTextBox,
} from "../create-project/style1";


interface DoneTabProps {
  selectedProject: IGetProject;
}

const DoneTab = ({ selectedProject }: DoneTabProps) => {
  const { lookup } = useContext(AppContext);
  const { 
    name, 
    category, 
    language, 
    platform,
    otherCategory,
    otherLanguage,
    otherPlatform,
    description, 
    cost,
    timeline
  } = selectedProject;
  
  console.log("timeline", timeline)

  return (
    <Wrapper style={{ marginTop: "-20px" }}>
      <div className="flex_1" style={{position:'sticky',top:'-20px',zIndex:'100'}}>
        <Flex>
          <VStack>
            <Header>Project details</Header>
            <Text>Order summary for the project</Text>
          </VStack>
        </Flex>
      </div>

      <Section>
        <Prompts title="Project name" />
        <CustomInput
          placeholder="Enter project name"
          type="text"
          value={name}
          disabled
        />
      </Section>

      <Section>
        <SelectProjectDetail
          title="What do you want to order?"
          lookUp={lookup?.ProjectCategory}
          initialSelectedKey={category}
          grid
        />
        {category === "other" && (
          <OthersTextBox
            placeholder="What do you want to order"
            disabled
            value={otherCategory}
            style={{...OthersTextBoxStyle}}
          />
        )}
      </Section>

      <Section>
        <SelectProjectDetail
          title="What is your desired solution language?"
          lookUp={lookup?.ProjectSolutionLanguage}
          initialSelectedKey={language}
          grid={false}
        />
        {language === "other" && (
          <OthersTextBox
            placeholder="What is your desired solution language"
            disabled
            value={otherLanguage}
            style={{...OthersTextBoxStyle}}
          />
        )}
      </Section>

      <Section>
        <SelectProjectDetail
          title="Choose your desired platform"
          lookUp={lookup?.ProjectSolutionPlatform}
          initialSelectedKey={platform}
          grid={false}
        />
        {platform === "other" && (
          <OthersTextBox
            placeholder="What is your desired platform"
            disabled
            value={otherPlatform}
            style={{...OthersTextBoxStyle}}
          />
        )}
      </Section>

      <Section>
        <Prompts title="Project description" />
        <Textarea
          placeholder="Enter project description"
          rows={6}
          value={description}
          disabled
        />
      </Section>

      <Section style={{ margin: "10px 0 -10px" }}>
        <Header>Cost and timeline</Header>
      </Section>

      <Section className="negotiable_budget">
        <Flex className="flex-budget">
          <VStack>
            <Prompts title="Cost" />
            <CustomInput
              value={cost.toString()}
              type="number"
              icon="$"
              style={{
                padding: "10px 16px 10px 40px",
              }}
              iconPosition={{ position: "absolute", left: "20px" }}
              disabled
            />
          </VStack>
        </Flex>

        <Flex className="flex-budget">
          <VStack>
            <Prompts title="Timeline" />
            <CustomSelect
              value={lookup?.ProjectTimeline.find((item)=>item.key===timeline)?.id ?? 0}
              title={timeline}
              size="size"
              name="timeline"
              data={
                lookup?.ProjectTimeline.map((item) => {
                  return { title: item.description, value: item.id };
                }) ?? []
              }
            />
          </VStack>
        </Flex>
      </Section>
    </Wrapper>
  )
}

export default DoneTab